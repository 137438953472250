import React, { Component } from 'react';
import Draggable from 'react-draggable';
import {UserData} from './types/types';
import '../css/ExamQuestion.css'; // Import the CSS file for styles
import ExamStart from './ExamStart'; // Import the ExamStart component
import ExamQuestion from './ExamQuestion';
import BreakScreen from './BreakScreen';
import Results from './Results';
import Timer from './tools/Timer';
import DesmosCalculator from './tools/DesmosCalculator';
import DisplayQuestions from './DisplayQuestions';
import HamburgerMenu from './tools/HamburgerMenu';
import { fetchQuestions } from './api/fetchQuestions'; // Assuming fetchQuestions is in the same directory
import { IQuestion } from './types/types'; // Adjust the path as necessary
import referenceImage from '../images/Ref_Sheet.png';
import { API_URL } from '../config';


enum ExamStatus {
    START,
    BREAK, // 10 minute
    EXAM,
    END
}

enum ExamTime {
    READINGANDWRITING = 32*60,
    MATH = 35*60,
    APMICROECO = 70*60,
    BREAK = 10*60
}

enum QuestionState {
    QUESTION,
    REVIEW
}

type ExamProps = {
  userData: UserData | null;
  ExamID: number; 
  TestID: string;
  isPractice: boolean;
};

type ExamState = {
  questions: IQuestion[];
  currentQuestions: IQuestion[];
  currentQuestionIndex: number;
  isMenuCollapsed: boolean;
  answers: {[questionID: string]: number | string | null};
  timePerAnswer : {[questionID: string]: number};// Object to hold answers
  singleChoiceInput: string;
  selectedOption: number | null;
  reviewSection: boolean;
  currentStatus: ExamStatus;
  timeLeft: ExamTime;
  currentSection: number;
  currentModule: number;
  headerSection: string;
  footerSection: string;
  isQuestionDisplayVisible: boolean;
  isMath: boolean;
  showCalculator: boolean;
  uniqueCombinations: { test: string; section: number; module: number }[];
  modSecIndex: number;
  timePerQuestion: number;
  questionTimerIntervalId: number | null;
  currentQuestionState: QuestionState;
  struckOptions: { [questionID: string]: Set<number> };
  showReference: boolean;
};

class Exam extends Component<ExamProps, ExamState> {
  private timerRef = React.createRef<Timer>();
  constructor(props: ExamProps) {
    super(props);
    this.state = {
      questions: [],
      currentQuestions: [],
      singleChoiceInput: "",
      selectedOption: null,
      currentQuestionIndex: 0,
      isMenuCollapsed: true,
      answers: {},
      timePerAnswer: {},
      currentStatus: ExamStatus.START,
      timeLeft: ExamTime.READINGANDWRITING,
      currentSection: 1,
      currentModule: 1,
      headerSection: "",
      footerSection: "",
      reviewSection: false,
      isQuestionDisplayVisible: false,
      isMath: false,
      showCalculator: false,
      uniqueCombinations: [],
      modSecIndex: 0,
      timePerQuestion: 0,
      questionTimerIntervalId: null,
      currentQuestionState: QuestionState.QUESTION,
      struckOptions: {},
      showReference: false,

    };
  }

  //Math components
  toggleCalculator = () => {
	this.setState(prevState => ({
	  showCalculator: !prevState.showCalculator
	}));
  };
  
  toggleReference = () => {
  this.setState(prevState => ({
    showReference: !prevState.showReference
  }));
};

  toggleStart = () => {
    const tempUniqueCombinations = this.getUniqueSectionModuleCombinations();
    const newSection = tempUniqueCombinations[this.state.modSecIndex].section;
    const newModule = tempUniqueCombinations[this.state.modSecIndex].module;
    const newTest = tempUniqueCombinations[this.state.modSecIndex].test;
    const newHeader = `Section ${newSection} Module ${newModule}: ${newTest}`;
    let newTime = 10 * 60;

    if (newTest === "Math") {
	newTime = ExamTime.MATH;
    } else if (newTest === "Reading and Writing") {
	newTime = ExamTime.READINGANDWRITING;
    } else if (newTest === "Microeconomics"){
	newTime = ExamTime.APMICROECO;
    }else {
	newTime = ExamTime.BREAK;
    }

    this.setState({
	currentStatus: ExamStatus.EXAM,
	currentQuestions: this.getQuestionForSectionAndModule(tempUniqueCombinations[this.state.modSecIndex].section, tempUniqueCombinations[this.state.modSecIndex].module),
	currentQuestionIndex: 0,
	currentSection: newSection,
	currentModule: newModule,
	headerSection: newHeader,
	footerSection: this.props.userData === null ? "" : this.props.userData.first_name + " " + this.props.userData.last_name,
	uniqueCombinations: tempUniqueCombinations,
    	isMath: newTest === "Math",
	timeLeft: newTime,

    });
    this.startQuestionTimer();
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuCollapsed: !prevState.isMenuCollapsed
    }));
  };

  onTimerEnd = () => {
    if(this.state.currentStatus !== ExamStatus.BREAK && this.props.isPractice && !this.state.reviewSection){
	return;
    }
      this.setState({reviewSection: false});
    const newDebugIndex = this.state.modSecIndex + 1;

    
/*    if(this.state.currentStatus === ExamStatus.BREAK){
	this.setState({
	    currentStatus: ExamStatus.EXAM,
	    timeLeft: ExamTime.BREAK,
	});
	this.resetQuestionTimer(0);
    } else {*/


        if(newDebugIndex >= 0 && newDebugIndex < this.state.uniqueCombinations.length){

	    const newSection = this.state.uniqueCombinations[newDebugIndex].section;
	    const newModule = this.state.uniqueCombinations[newDebugIndex].module;
	    const newTest = this.state.uniqueCombinations[newDebugIndex].test;
	    const newHeader = `Section ${newSection} Module ${newModule}: ${newTest}`;
	    let newTime = 10 * 60;

	    if (newTest === "Math") {
		newTime = ExamTime.MATH;
	    } else if (newTest === "Reading and Writing") {
		newTime = ExamTime.READINGANDWRITING;
	    } else if (newTest === "Microeconomics"){
		newTime = ExamTime.APMICROECO;
	    }else {
		newTime = ExamTime.BREAK;
	    }

	    if (this.state.currentStatus !== ExamStatus.BREAK && newSection > this.state.currentSection) {
		this.setState({
		    currentStatus: ExamStatus.BREAK,
		    timeLeft: ExamTime.BREAK,
		});
		this.stopQuestionTimer();
		return;
	    }

	    this.setState({
		currentStatus: ExamStatus.EXAM,
	        modSecIndex: newDebugIndex,
		isMath: newTest === "Math",
		currentSection: newSection,
		currentModule: newModule,
		currentQuestions: this.getQuestionForSectionAndModule(newSection, newModule),
		timeLeft: newTime,
		headerSection: newHeader,
	        currentQuestionIndex: 0,
	    }, () => {
		this.timerRef.current?.resetTimer();
	    });
	    this.resetQuestionTimer(0);

	 } else {
	    this.setState({
		currentStatus: ExamStatus.END,
		currentQuestions: [],
	    });
	 }
   // }
};

    toggleQuestionDisplay = () => {
	this.setState(prevState => ({
	    isQuestionDisplayVisible: !prevState.isQuestionDisplayVisible
	}));
    }

getUniqueSectionModuleCombinations = (): { test: string; section: number; module: number }[] => {
  const uniqueCombinations = new Set<string>();

  this.state.questions.forEach(question => {
    // Use pipe as delimiter to handle spaces in Test values
    const combination = `${question.Test}|Section ${question.Section}|Module ${question.Module}`;
    uniqueCombinations.add(combination);
  });

  // Convert the Set into an array and sort it
  const sortedCombinations = Array.from(uniqueCombinations).sort((a, b) => {
    const partsA = a.split('|');
    const partsB = b.split('|');
    const testA = partsA[0];
    const testB = partsB[0];
    const sectionA = parseInt(partsA[1].split(' ')[1]);
    const sectionB = parseInt(partsB[1].split(' ')[1]);
    const moduleA = parseInt(partsA[2].split(' ')[1]);
    const moduleB = parseInt(partsB[2].split(' ')[1]);

    // Then compare sections
    if (sectionA !== sectionB) {
      return sectionA - sectionB;
    }
    // If sections are equal, compare modules
    return moduleA - moduleB;
  });

  // Map the sorted strings back to objects, including the Test property
  return sortedCombinations.map(combination => {
    const parts = combination.split('|');
    return { 
      test: parts[0], // Now correctly handles values with spaces
      section: parseInt(parts[1].split(' ')[1]), 
      module: parseInt(parts[2].split(' ')[1]) 
    };
  });
};

//TODO: Remove this as a function within Exam and put it into API
  componentDidMount() {
    // Replace 'http://localhost:5000/get_questions' with the actual endpoint
    fetchQuestions(`${API_URL}/get_exam_questions?testID=${this.props.TestID}`, false)
      .then((questions: IQuestion[]) => {
      // Initialize an empty object for the answers
        const initialAnswers: {[questionID: string]: number | string | null} = {};
	const initialTime: {[questionID: string]: number} = {};
      // Populate the initialAnswers object with question IDs set to null
      questions.forEach((question) => {
        initialAnswers[question.QuestionID] = null;
	initialTime[question.QuestionID] = 0;
      });
      // Set the questions and initialAnswers to the state
      this.setState({ questions, answers: initialAnswers, timePerAnswer: initialTime });
//      this.setState({uniqueCombinations: this.getUniqueSectionModuleCombinations()});
      })
      .catch(error => console.error('Error fetching questions:', error));

  }

  getQuestionForSectionAndModule(section: number, module: number) {
    const { questions } = this.state;

    this.setState({
	currentQuestionIndex: 0,
	singleChoiceInput: "",
	selectedOption: null

	});
    return questions.filter(question => question.Section === section && question.Module === module);
  }

  handleOptionSelect = (option: number) => {

    const questionID = this.state.currentQuestions[this.state.currentQuestionIndex].QuestionID;
    this.setState({selectedOption: option});
	this.setState(prevState => ({
	    answers: {
		...prevState.answers,
		[questionID]: option
	    }
	}));
  };

  handleSingleChoiceInput = (input: string) => {
      
    const questionID = this.state.currentQuestions[this.state.currentQuestionIndex].QuestionID;
    this.setState({singleChoiceInput: input});

    this.setState(prevState => ({
	  answers: {
		...prevState.answers,
		[questionID]: input
	  }
	}));
  };
  
  toggleStrikeThrough = (questionID: string, optionIndex: number) => {
  this.setState(prevState => {
    const updatedStruckOptions = { ...prevState.struckOptions };
    const currentOptions = updatedStruckOptions[questionID] || new Set();

    if (currentOptions.has(optionIndex)) {
      currentOptions.delete(optionIndex);
    } else {
      currentOptions.add(optionIndex);
    }

    updatedStruckOptions[questionID] = new Set(currentOptions);

    // Log the updated state for debugging
    console.log(`Updated Struck Options for ${questionID}: `, updatedStruckOptions[questionID]);

    return { struckOptions: updatedStruckOptions };
  });
};


  handleResponse = () => {
      if(this.state.reviewSection){
	  this.setState({reviewSection: false});
      }
    const {currentQuestions, currentQuestionIndex, singleChoiceInput, selectedOption} = this.state;
    const questionID = currentQuestions[currentQuestionIndex].QuestionID;
    const isMulti = currentQuestions[currentQuestionIndex].Multichoice;
    const responseChoice = singleChoiceInput;
    const optionSelected = selectedOption;

    if(isMulti){
      if(optionSelected){
        this.setState(prevState => ({
          answers: {
            ...prevState.answers,
            [questionID]: optionSelected
          },

        }));
        this.setState({selectedOption: null});
      }
    } else {
      if (responseChoice !== ""){
        this.setState(prevState => ({
          answers: {
            ...prevState.answers,
            [questionID]: responseChoice
          },
        }));
        this.setState({singleChoiceInput: ""});
      }
    }
  if(!this.state.reviewSection){
	this.setState( prevState => ({
	    timePerAnswer: {
	      ...prevState.timePerAnswer,
	      [questionID]: this.state.timePerQuestion
	  }
	  }));
    }
  }

  startQuestionTimer = () => {
      const intervalID = setInterval(() => {
	  this.setState(prevState => ({
	      timePerQuestion: prevState.timePerQuestion + 1}));
	    }, 1000) as unknown as number;

      this.setState({questionTimerIntervalId: intervalID});
  }

  resetQuestionTimer = (currentTimer: number) => {
      if(this.state.questionTimerIntervalId){
	  clearInterval(this.state.questionTimerIntervalId);
	  }

      this.setState({timePerQuestion: currentTimer}, () => {
	  this.startQuestionTimer();
      });
  }

  stopQuestionTimer = () => {
      if(this.state.questionTimerIntervalId){
	  clearInterval(this.state.questionTimerIntervalId);
	  this.setState({questionTimerIntervalId: null});
	  }
  }

  goToReviewPage = () => {
    this.setState({
	reviewSection: true,
	isQuestionDisplayVisible: false,
	currentQuestionIndex: this.state.currentQuestions.length - 1,
	currentQuestionState: QuestionState.REVIEW,
	});
    this.stopQuestionTimer();
  }

  goToNextQuestion = () => {
    if(this.state.currentQuestionIndex + 1 === this.state.currentQuestions.length){
	this.handleResponse();
	this.setState({
	    reviewSection: true,
	    isQuestionDisplayVisible: false,
	    currentQuestionState: QuestionState.REVIEW,
        });

	this.stopQuestionTimer();
	return;
    }

    this.handleResponse();
    const nextQuestionIndex = this.state.currentQuestionIndex + 1;
    const nextQuestionID = this.state.currentQuestions[nextQuestionIndex].QuestionID;
    const existingAnswer = this.state.answers[nextQuestionID];
    const currentTime = this.state.timePerAnswer[nextQuestionID];
   
    this.resetQuestionTimer(currentTime);

    if(typeof existingAnswer === "string"){
      this.setState({
      currentQuestionIndex: nextQuestionIndex,
      singleChoiceInput: existingAnswer,
      selectedOption: null,
      });
    } else if(typeof existingAnswer == "number"){
      this.setState({
        currentQuestionIndex: nextQuestionIndex,
        singleChoiceInput: "",
        selectedOption: existingAnswer,
        });
    } else {
      this.setState({
        currentQuestionIndex: nextQuestionIndex,
        singleChoiceInput: "",
        selectedOption: null,
        });
    }
  };

  goToPreviousQuestion = () => {

    this.handleResponse();
    const prevQuestionIndex = this.state.reviewSection ? this.state.currentQuestionIndex : this.state.currentQuestionIndex - 1;
    const prevQuestionID = this.state.currentQuestions[prevQuestionIndex].QuestionID;
    const existingAnswer = this.state.answers[prevQuestionID];
    const currentTime = this.state.timePerAnswer[prevQuestionID];
    
    if(this.state.reviewSection){
	this.setState({reviewSection: false});
    }
    this.resetQuestionTimer(currentTime);

    

    if(typeof existingAnswer === "string"){
      this.setState({
      currentQuestionIndex: prevQuestionIndex,
      singleChoiceInput: existingAnswer,
      selectedOption: null,
      });
    } else if(typeof existingAnswer == "number"){
      this.setState({
        currentQuestionIndex: prevQuestionIndex,
        singleChoiceInput: "",
        selectedOption: existingAnswer,
        });
    } else {
      this.setState({
        currentQuestionIndex: prevQuestionIndex,
        singleChoiceInput: "",
        selectedOption: null,
        });
    }
  };

  toggleReviewMode = () => {
    return;
  }

  goToQuestion = (questionIndex: number) => {
    this.handleResponse();
    const questionID = this.state.currentQuestions[questionIndex].QuestionID;
    const existingAnswer = this.state.answers[questionID];
    if (this.state.currentQuestionIndex !== questionIndex){
        this.resetQuestionTimer(this.state.timePerAnswer[questionID]);
    }
  
    if(typeof existingAnswer === "string"){
      this.setState({
      currentQuestionIndex: questionIndex,
      singleChoiceInput: existingAnswer,
      selectedOption: null,
      });
    } else if(typeof existingAnswer == "number"){
      this.setState({
        currentQuestionIndex: questionIndex,
        singleChoiceInput: "",
        selectedOption: existingAnswer,
        });
    } else {
      this.setState({
        currentQuestionIndex: questionIndex,
        singleChoiceInput: "",
        selectedOption: null,
        });
    }
  };

    getCurrentQuestion = () => {
	
    }
    
  render() {
	  {console.log(this.props.ExamID)}
    const { isMath, showCalculator, isMenuCollapsed, answers, currentQuestions, currentQuestionIndex, currentStatus, timeLeft, headerSection, footerSection, reviewSection } = this.state;
    const currentQuestion = currentQuestions[currentQuestionIndex];
    return (<div>
    {currentStatus === ExamStatus.START && this.state.uniqueCombinations && <div> <ExamStart toggleStart={this.toggleStart}/> </div>}
    {currentStatus === ExamStatus.BREAK && <BreakScreen timeLeft={timeLeft} onTimerEnd={this.onTimerEnd}/>}
    {currentStatus == ExamStatus.END && this.props.userData && <div> <HamburgerMenu role={this.props.userData.role} firstName={this.props.userData.first_name} lastName={this.props.userData.last_name}/><Results isPractice={this.props.isPractice} cName = {headerSection} addToDB={true} answers={answers} times={this.state.timePerAnswer} questions={this.state.questions} ExamID={this.props.ExamID} TestID= {this.props.TestID} UserID={this.props.userData.userID} />  </div>}
    {(currentStatus !== ExamStatus.START && currentStatus !== ExamStatus.BREAK && currentStatus !== ExamStatus.END) &&
      <div className="exam-question">
        <div className="exam-header">
          <h3 className="header-text">{this.props.isPractice ? "Practice " + headerSection : headerSection}</h3>
	  <Timer ref={this.timerRef} key = {currentStatus} initialTime={timeLeft} onTimerEnd={this.onTimerEnd}/>
		
	  <div> 
	    {isMath && (	
		<>
		<div className="math-buttons">
	        <button onClick={this.toggleCalculator} className="calculator-toggle">Calculator</button>
	        <button onClick={this.toggleReference} className="calculator-toggle">Reference</button>

	        </div>
	        {this.state.showReference && (
		  <Draggable handle=".handle">
		    <div className="reference-container">
		      <div className="handle" style={{ backgroundColor: 'black', color: 'white' }}>
			Reference Header
			<button 
			  onClick={this.toggleReference} 
			  className = "exit-button"
			>
			  X
			</button>
		      </div>
		      <img src={referenceImage} alt="Reference" />
		    </div>
		  </Draggable>
		)}
		{showCalculator && (
		 <Draggable handle=".handle">
		     <div className="calculator-container">
		     <div className="handle"></div>
		        <DesmosCalculator />
		    </div>
		</Draggable>
	    )}
		</>
	    )}
	    </div>
        </div>

        {currentQuestion && reviewSection ? 
	(
	  <DisplayQuestions
	    header={headerSection}
            currentQuestions={this.state.currentQuestions}
            answers={this.state.answers}
            goToQuestion={this.goToQuestion}
	    isReview={true}
            isVisible={false}
            toggleQuestionDisplay={this.toggleReviewMode}
	    toReviewPage={this.goToReviewPage}
          />) : 
	  (<ExamQuestion
            index = {currentQuestionIndex}
            passage = {currentQuestion.Passage}
            question={currentQuestion.Question}
            choices={currentQuestion.Choices}
            selectedOption={this.state.selectedOption}
            singleChoiceInput={this.state.singleChoiceInput}
            onOptionSelect={this.handleOptionSelect}
            onSingleChoiceInput={this.handleSingleChoiceInput}
            headerSection={headerSection}
            questionID={currentQuestion.QuestionID}
            imageQuestion={currentQuestion.Image}
            multiChoice={currentQuestion.Multichoice}
            struckOptions={this.state.struckOptions[currentQuestion.QuestionID] || new Set()}
	    toggleStrikeThrough={(optionIndex: number) => this.toggleStrikeThrough(currentQuestion.QuestionID, optionIndex)}
            />
        )}

        <div className="exam-footer">
	    <div className="footer-text">
		{footerSection}
	    </div>
            {!reviewSection && (<button className="toggle-display-questions" onClick={this.toggleQuestionDisplay}>
	    {this.state.isQuestionDisplayVisible ? 'Hide Questions' : 'Show Questions'}
            </button>
	    )}


	    <div className="button-container">
		{currentQuestionIndex > 0 && (
	          <button onClick={this.goToPreviousQuestion}>Back</button>
		)}
		{currentQuestionIndex < currentQuestions.length && !reviewSection && (
              <button onClick={this.goToNextQuestion}>Next</button>
		)}
		{reviewSection && (
		<button onClick={this.onTimerEnd}>Next</button>
		)}
	    </div>
        </div>

	     {this.state.isQuestionDisplayVisible && (
	    <DisplayQuestions
	      header = {headerSection}
	      currentQuestions={this.state.currentQuestions}
	      answers={this.state.answers}
              goToQuestion={this.goToQuestion}
	      isReview={false}
	      isVisible={this.state.isQuestionDisplayVisible}
	      toggleQuestionDisplay={this.toggleQuestionDisplay}
	      toReviewPage={this.goToReviewPage}
            />)}
      </div>
      }
      </div>
    );
  }
}

export default Exam;
