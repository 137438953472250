import React, {Component} from 'react';
import axios from 'axios';
import {NavigateFunction} from 'react-router';
import {Tests, ResultHeader, IQuestion, UserData} from './types/types';
import { fetchQuestions } from './api/fetchQuestions';
import Results from './Results';
import '../css/StudentProfile.css';
import { API_URL } from '../config';


type StudentProfileProps = {
    navigate: NavigateFunction | null;
    studentData: UserData | null;
}

type StudentProfileState = {
    tests: Tests[];
    resultsHeader: ResultHeader[];

    isLoading: boolean;
    isPractice: boolean;
    cResultID: string | null;
    cName: string;
    cExamID: number | null;
    cTestID: string | null;
    answersData: AnswersType | null;
    timesData: TimesType | null;
    questionsData: IQuestion[] | null;
}

type AnswersType = { [questionID: string]: number | string | null };
type TimesType = { [questionID: string]: number };

class StudentProfile extends Component<StudentProfileProps, StudentProfileState> {
	constructor(props: StudentProfileProps) {
		super(props);
		this.state = {
		    tests: [],
		    resultsHeader: [],
		    isLoading: false,
		    isPractice: false,
		    cName: "",
		    cResultID: null,
		    cExamID: null,
		    cTestID: null,
		    questionsData: null,
		    answersData: null,
		    timesData: null,
		}
	}

    prepareResultsData = (resultsData: ResultHeader[]) => {
        return resultsData.map(result => {
            let section1Score = null;
            let section2Score = null;

            result.Sections.forEach(section => {
                if (section.TestSection === 1) {
                    section1Score = section.TestScore;
                } else if (section.TestSection === 2) {
                    section2Score = section.TestScore;
                }
            });

            return {
                ...result,
                Section1Score: section1Score,
                Section2Score: section2Score,
            };
        });
    };
	componentDidMount() {
	    this.loadData();
	}

	componentDidUpdate(prevProps: StudentProfileProps) {
	    if (prevProps.studentData !== this.props.studentData) {
		this.loadData();
	    }
	}

	loadData() {
	    if(this.props.studentData){
	    const urlTests = `${API_URL}/get_student_tests?studentID=${this.props.studentData.userID}`;

	    axios.get(urlTests)
		.then(response => {
		    this.setState({
			tests: response.data
		    });
		})
		.catch(error => {
		    console.log(error);
		});
	    
	    const urlResults = `${API_URL}/get_student_results_list?studentID=${this.props.studentData.userID}`;
	
	    axios.get(urlResults)
		.then(response => {
		    const preparedResultsHeader = this.prepareResultsData(response.data);
		    this.setState({
			resultsHeader: preparedResultsHeader
		    });
		    console.log(response.data);
		})
		.catch(error => {
		    console.log(error);
		});
		}


	}
//TODO: Link the boolean is practice to the exam, so that we can disregard the timer during practice
	handleStartExam = (selectedExamID: number, selectedTestID: string, isPractice: boolean) => {
	    if (selectedTestID && this.props.studentData && this.props.navigate) {
		const header = "Section 1, Module 1: Reading and Writing";
		const footer = this.props.studentData.first_name + " " + this.props.studentData.last_name;

		this.props.navigate(`/exam`, {state: {ExamID: selectedExamID, TestID: selectedTestID, userData: this.props.studentData, isPractice: isPractice} });

	    }
	};

getResults = (ExamID: number, ResultID: string) => {
    this.setState({isLoading: true});
    axios.get(`${API_URL}/get_testID?resultID=${ResultID}`)
        .then(response => {
            console.log(response.data[0].TestID);
            const TestID = response.data[0].TestID;
	    axios.get(`${API_URL}/get_current_test_info?examID=${ExamID}&testID=${TestID}&userID=${this.props.studentData?.userID}`)
	    .then (response => {
		console.log(response.data);
		this.setState({isPractice: response.data.practice, cName: response.data.name});
	    }).catch((error: any) => {
		console.log(error);
	    });

            fetchQuestions(`${API_URL}/get_exam_questions?testID=${TestID}`, false)
            .then((questions: IQuestion[]) => {
                console.log(questions);
                axios.get(`${API_URL}/get_result_answers?resultID=${ResultID}`)
                .then(response => {
                    console.log(response.data);
                    const answers: AnswersType = {};
                    const times: TimesType = {};
                    questions.forEach((question) => {
                        response.data.forEach((result: any) => {
                            if (result.QuestionID === question.QuestionID) {
                                times[question.QuestionID] = result.time;
				answers[question.QuestionID] = null;
                                if(question.Multichoice){
                                    let count = 0;
                                    question.Choices.forEach((choice) => {
                                        if (choice[1] == result.answer) {
                                            answers[question.QuestionID] = count;
                                        }
                                        count++;
                                    });
                                } else {
                                    answers[question.QuestionID] = result.answer ? result.answer : null;
                                }
                            }
                        });
                    });
		    this.setState({
			cResultID: ResultID,
			cExamID: ExamID,
			cTestID: TestID,
			questionsData: questions,
			answersData: answers,
			timesData: times,
			isLoading: false,
		    });
                })
                .catch((error: any) => {
                    console.log(error);
                });
            })
            .catch((error: any) => console.error('Error fetching questions:', error));
        })
        .catch((error: any) => {
            console.log(error);
        });
}

// Utility function to format the date
formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit'
  });
};
    handleBackToResults = () => {
	this.setState({cResultID: null, cExamID: null, cTestID: null, questionsData: null, answersData: null, timesData: null});
    };

	render() {
	    const {tests, resultsHeader, isLoading, cResultID, cExamID, cTestID, questionsData, answersData, timesData} = this.state;
	    if (isLoading) {
		return <div>Loading...</div>;
	    }
	    if (this.props.studentData && cResultID && cExamID && cTestID && questionsData && answersData && timesData) {
		return (
		    <div>
			<div className="backSection">
			    <button className="backButton" onClick={() => this.handleBackToResults()}>Back To Results</button>
			    <select style={{marginLeft: "20px"}}value = {cResultID} onChange={(e) => {
				    const [cResId, cExamId] = e.target.value.split(',');

				    this.getResults(Number(cExamId), cResId);
			    	}
			    }>
	{resultsHeader.map((result) => (
	    <option key={result.ResultID} value={`${result.ExamID},${result.ResultID}`}>{result.TestName}
	    </option>	
	))}
			    </select>
			</div>
			<Results isPractice={this.state.isPractice} cName={this.state.cName} addToDB={false} questions={questionsData} answers={answersData} times={timesData} ExamID={cExamID} TestID={cTestID} UserID={this.props.studentData.userID}/>
		    </div>
		    );
	    }

		return (
			<div className="student-profile-container">
				{this.props.studentData ? (<h1 className="student-profile-header">{this.props.studentData.first_name} {this.props.studentData.last_name}'s Profile</h1>) : (<h1 className="student-profile-header">Student Profile</h1>)}
				{tests.length > 0 &&
				<>
				<h2>Currently Assigned Tests</h2>
				<table className="student-profile-table">
				    <thead>
					<tr>
					    <th>Test Name</th>
					    <th>Test Type</th>
					    <th>Test Assigned</th>
					    <th>Practice</th>
					    {this.props.navigate && <th>Start Test</th>}
					</tr>
				    </thead>
				    <tbody>
	    				{tests.map((test) => (
					    <tr key={test.ExamID}>
						<td>{test.TestName}</td>
						<td>{test.TestType}</td>
						<td>{test.TestDateAssigned && this.formatDate(test.TestDateAssigned)}</td>
						<td>{test.TestPractice ? "Yes" : "No"}</td>
						{this.props.navigate && <td><button onClick={() => this.handleStartExam(test.ExamID, test.TestID, test.TestPractice || false)}>Start Test</button></td>}
					    </tr>
					))}
				    </tbody>
				</table>
				</>
				}

				{resultsHeader.length > 0 &&
				<>
				<h2>Past Test Results</h2>
				<table className="student-profile-table">
				    <thead>
					<tr>
					    <th>Test Name</th>
					    <th>Test Type</th>
					    <th>Test Date</th>
					    <th>Section 1</th>
					    <th>Section 2</th>
					    {/*<th>Practice</th>*/}
					    <th>View Test Results</th>
					</tr>
				    </thead>
				    <tbody>
	    				{resultsHeader.map((result) => (
					    <tr key={result.ResultID}>
						<td>{result.TestName}</td>
						<td>{result.TestType}</td>
						<td>{result.TestTakenAt && this.formatDate(result.TestTakenAt)}</td>
						<td>{result.Section1Score !== null ? result.Section1Score : 'N/A'}</td>
						<td>{result.Section2Score !== null ? result.Section2Score : 'N/A'}</td>
						{/*<td>{result.TestPractice ? "Yes" : "No"}</td>*/}
						<td><button onClick={() => this.getResults(result.ExamID, result.ResultID)}>View</button></td>
					    </tr>
					))}
				    </tbody>
				</table>
				</>
				}
			</div>
		)
	}
}
export default StudentProfile;
