import axios from 'axios';
import { IQuestion, IQuestions, IChoice, Tests } from '../types/types'; // Adjust the path as necessary

//Uses Fisher-Yates Shuffling technique, it's a bit costly so I might consider using just SQL to randomize the initial question order
function shuffleArray<T>(array: T[]): T[] {
    const shuffledArray = [...array]; // Create a copy of the array to not mutate the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const j = Math.floor(Math.random() * (i + 1));
      // Swap elements at indices i and j
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

export const fetchTestQuestions = async (url: string): Promise<Tests[]> => {
    try{
	const response = await axios.get(url);

	const testData: Tests[] = response.data.map((data: any): Tests => {
	    	return {
			ExamID: data.examID,
			TestID: data.testID,
			TestName: data.name,
			TestType: data.testType
		};
	});
    return testData;
    } catch (error) {
        console.error('There was an error fetching the tests!', error);
	throw error;
    }
};

export const fetchQuestions = async (url: string, shuffle: boolean): Promise<IQuestions> => {
  try {
    const response = await axios.get(url);
    // Assuming the API returns an object with keys being QuestionIDs and values being question data
    const rawData: Record<string, any> = response.data;
    
    const questionsData: IQuestions = Object.entries(rawData).map(([questionId, data]): IQuestion => {
      // Here we ensure the data matches the structure of IQuestion, including the Choices
      const choices: IChoice[] = data.Choices.map((choice: [number, string, boolean, string]) => {
        // The choice is already a tuple of [number, string, boolean], so we can return it directly
        return choice;
      });
      
      return {
        QuestionID: questionId,
	Section: data.Section,
	Module: data.Module,
        Assessment: data.Assessment,
        Test: data.Test,
        Domain: data.Domain,
        Skill: data.Skill,
        Passage: data.Passage,
        Question: data.Question,
        Difficulty: data.Difficulty,
        Correction: data.Correction,
        Approved: data.Approved,
        Multichoice: data.Multichoice,
        Choices: choices,
        Image: data.Image
      };
    });
    if (shuffle){
      return shuffleArray(questionsData);
    } 
    return questionsData;
  } catch (error) {
    console.error('There was an error fetching the questions!', error);
    throw error;
  }
};
